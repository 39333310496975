@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Pacifico&family=Poppins:wght@100&display=swap);
body {
    font-family: 'Montserrat', sans-serif;
    line-height: 3vw;
    overflow: hidden;
    font-size: 1.2vw;
}

.main-container {
  display: flex;
}

.container {
  margin: 3.3vw;
  margin-top: 6vw;
  padding: 1.5vw;
  width: 60vw;
}

.live-info {
  height: 10vw;
}

.bg-mask {
  /*
  Ensure background is added first
  */  
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZlcnNpb249JzEuMScgd2lkdGg9JzQwMCcgaGVpZ2h0PSc0MDAnPgoJPGRlZnMgaWQ9J2RlZnM0Jz4KCQk8ZmlsdGVyIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0nc1JHQicgaWQ9J2ZpbHRlcjMxMTUnPgoJCQk8ZmVUdXJidWxlbmNlIHR5cGU9J2ZyYWN0YWxOb2lzZScgbnVtT2N0YXZlcz0nMScgYmFzZUZyZXF1ZW5jeT0nMC45JyBpZD0nZmVUdXJidWxlbmNlMzExNycgLz4KCQkJPGZlQ29sb3JNYXRyaXggcmVzdWx0PSdyZXN1bHQ1JyB2YWx1ZXM9JzEgMCAwIDAgMCAwIDEgMCAwIDAgMCAwIDEgMCAwIDAgMCAwIDYgLTMuNzUgJyBpZD0nZmVDb2xvck1hdHJpeDMxMTknIC8+CgkJCTxmZUNvbXBvc2l0ZSBpbjI9J3Jlc3VsdDUnIG9wZXJhdG9yPSdpbicgaW49J1NvdXJjZUdyYXBoaWMnIHJlc3VsdD0ncmVzdWx0NicgaWQ9J2ZlQ29tcG9zaXRlMzEyMScgLz4KCQkJPGZlTW9ycGhvbG9neSBpbj0ncmVzdWx0Nicgb3BlcmF0b3I9J2RpbGF0ZScgcmFkaXVzPScxMCcgcmVzdWx0PSdyZXN1bHQzJyBpZD0nZmVNb3JwaG9sb2d5MzEyMycgLz4KCQk8L2ZpbHRlcj4KCTwvZGVmcz4KCTxyZWN0IHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEwMCUnIHg9JzAnIHk9JzAnIGlkPSdyZWN0Mjk4NScgZmlsbD0nI2VlZWVlZScvPiAgICAgCgk8cmVjdCB3aWR0aD0nMTAwJScgaGVpZ2h0PScxMDAlJyB4PScwJyB5PScwJyBpZD0ncmVjdDI5ODUnIHN0eWxlPSdmaWxsOiNlMDg3Mjg7ZmlsdGVyOnVybCgjZmlsdGVyMzExNSknIC8+Cjwvc3ZnPg==);
  
   background-attachment: fixed;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  
  border-right: .15em solid orange;
  font-size: 3.5vw;
  font-weight: bold;
  text-align: center;
}

.lets-talk {
  margin-top: 5vw;
  margin-bottom: 0.85vw;
  font-family: 'Pacifico', cursive;
}

.link {
  color: inherit;
  text-decoration: none;
}

.logo {
  width: 3.3vw;
  height: 3.3vw;
  margin: 0.85vw;
  padding: 0.4vw;
}

.side-image {
  margin: 1.5vw;
  padding: 0.8vw;
  width: 48vw;
}

.inner-image{
  width: 44vw;
  height: 44vw;
}

/* responsive design for mobile phone resolutions */ 
@media screen and (max-width: 480px) {

  body {
    font-family: 'Montserrat', sans-serif;
    line-height: 6vw;
    overflow: hidden;
    font-size: 2.4vw;
  }

  .main-container {
    display: flex;
    flex-direction: column;
  }

  .container {
    margin: 6.6vw;
    margin-top: 12vw;
    padding: 3vw;
    width: 120vw;
  }

  .live-info {
    height: 20vw;
  }

  .bg-mask {
    /*
    Ensure background is added first
    */  
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZlcnNpb249JzEuMScgd2lkdGg9JzQwMCcgaGVpZ2h0PSc0MDAnPgoJPGRlZnMgaWQ9J2RlZnM0Jz4KCQk8ZmlsdGVyIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0nc1JHQicgaWQ9J2ZpbHRlcjMxMTUnPgoJCQk8ZmVUdXJidWxlbmNlIHR5cGU9J2ZyYWN0YWxOb2lzZScgbnVtT2N0YXZlcz0nMScgYmFzZUZyZXF1ZW5jeT0nMC45JyBpZD0nZmVUdXJidWxlbmNlMzExNycgLz4KCQkJPGZlQ29sb3JNYXRyaXggcmVzdWx0PSdyZXN1bHQ1JyB2YWx1ZXM9JzEgMCAwIDAgMCAwIDEgMCAwIDAgMCAwIDEgMCAwIDAgMCAwIDYgLTMuNzUgJyBpZD0nZmVDb2xvck1hdHJpeDMxMTknIC8+CgkJCTxmZUNvbXBvc2l0ZSBpbjI9J3Jlc3VsdDUnIG9wZXJhdG9yPSdpbicgaW49J1NvdXJjZUdyYXBoaWMnIHJlc3VsdD0ncmVzdWx0NicgaWQ9J2ZlQ29tcG9zaXRlMzEyMScgLz4KCQkJPGZlTW9ycGhvbG9neSBpbj0ncmVzdWx0Nicgb3BlcmF0b3I9J2RpbGF0ZScgcmFkaXVzPScxMCcgcmVzdWx0PSdyZXN1bHQzJyBpZD0nZmVNb3JwaG9sb2d5MzEyMycgLz4KCQk8L2ZpbHRlcj4KCTwvZGVmcz4KCTxyZWN0IHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEwMCUnIHg9JzAnIHk9JzAnIGlkPSdyZWN0Mjk4NScgZmlsbD0nI2VlZWVlZScvPiAgICAgCgk8cmVjdCB3aWR0aD0nMTAwJScgaGVpZ2h0PScxMDAlJyB4PScwJyB5PScwJyBpZD0ncmVjdDI5ODUnIHN0eWxlPSdmaWxsOiNlMDg3Mjg7ZmlsdGVyOnVybCgjZmlsdGVyMzExNSknIC8+Cjwvc3ZnPg==);
    
     background-attachment: fixed;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    
    border-right: .3em solid orange;
    font-size: 7vw;
    font-weight: bold;
    text-align: center;
  }

  .lets-talk {
    margin-top: 10vw;
    margin-bottom: 1.7vw;
    font-family: 'Pacifico', cursive;
  }

  .link {
    color: inherit;
    text-decoration: none;
  }

  .logo {
    width: 6.6vw;
    height: 6.6vw;
    margin: 1.7vw;
    padding: 0.8vw;
  }

  .side-image {
    margin: 3vw;
    padding: 1.6vw;
    width: 96vw;
  }

  .inner-image{
    width: 88vw;
    height: 88vw;
  }

}



